<template>
  <div>
    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-4">
        <b-col cols="4" class="p-0">
          <PageTitle v-bind:title="'Editions'" />
        </b-col>
        <b-col cols="8" class="text-right" v-if="!config.editable">
          <BibLabelSelectPI class="mb-3" />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <div v-if="$store.state.user.settings.isBiblabelFiltered && config.editable" class="mb-3 text-orange">
            <b-icon icon="exclamation-circle-fill" aria-hidden="true"></b-icon>
            Entries in this table are filtered by personal preference for bibliography set in user settings
            ({{ $store.getters.getBibLabelsByIds($store.state.user.settings.biblabels).map(biblabel => biblabel.label).join(', ') }})
          </div>
          <b-button v-if="config.editable" variant="primary" :disabled="!$store.getters.checkRole('legam.bib.edition.add')" size="sm" class="float-right mb-4" v-b-modal.modal-new-edition>
            <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
            Add new edition
          </b-button>
          <b-modal v-if="config.editable" id="modal-new-edition" title="New edition" ok-title="Save" @ok="saveEdition" size="lg">
            <EditionNewEdit />
          </b-modal>
          <LegamDataTables
            v-if="config.editable"
            v-bind="$data"
            :additionalData="tableAdditionalData"
            @selectChanged="selectChanged"
          />
          <LegamDataTablesClientSide
            v-else
            v-bind="$data"
            :additionalData="tableAdditionalData"
            @click="openDetails"
            :additionalDataFilter="tableAdditionalDataFilter"
            ref="PITable"
          />
        </b-col>
      </b-row>
    </b-container>
    <div class="merge-float" v-if="selected.length && config.editable">
      <b-badge href="#" variant="info" class="mr-1" v-for="edition in selected" :key="edition.id" @click="removeSelected(edition)">
        <span v-html="edition.sigleLegam"></span>
        <b-icon icon="trash" aria-hidden="true" class="ml-1"></b-icon>
      </b-badge>
      <span class="btn-legam-xxs btn-legam-xs btn-legam-blue" v-if="selected.length > 1" v-b-modal.modal-merge>
        <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
        Merge editions
      </span>
      <span class="btn-legam-xxs btn-legam-xs" @click="selected = []">
        <b-icon icon="x" aria-hidden="true"></b-icon>
      </span>
      <b-modal id="modal-merge" title="Merge editions" ok-title="Merge" @ok="merge" size="md">
        <b-badge href="#" variant="info" class="mr-1" v-for="edition in selected" :key="'modal' + edition.id" @click="removeSelected(edition)">
          <span v-html="edition.sigleLegam"></span>
          <b-icon icon="trash" aria-hidden="true" class="ml-1"></b-icon>
        </b-badge>
        <b-form-group
          id="main-entity-grp"
          label="Merge to"
          label-for="main-entity"
          class="mt-3"
          description="This edition will keep all its data (other editions will move their data to this edition and then disappear)"
        >
          <b-form-select
            v-model="mainEntityId"
            :options="selected.map(edition => {
              return {
                'text': edition.sigleLegam,
                'value': edition.id
              }
            })"
          ></b-form-select>
        </b-form-group>
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
  * >>> .tdAction {
    width: 70px;
  }
  .merge-float {
    position: fixed;
    bottom: 15px;
    left: 20px;
    background-color: #bebebe9a;
    padding: 3px 8px 5px;
    border-radius: 3px;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import LegamDataTables from '@/components/LegamDataTables.vue'
  import LegamDataTablesClientSide from '@/components/LegamDataTablesClientSide.vue'
  import BibLabelSelectPI from '@/components/Bibliography/BibLabelSelectPI.vue'
  import tdColAction from "@/components/tdColAction"
  import EditionNewEdit from "@/components/Bibliography/EditionNewEdit"
  import HelpStore from '@/store/helpstore.js'
  import config from '@/config.js'

  export default {
    name: 'Editions',
    data() {
      return {
        columns: [
          { title: 'Sigle', field: 'sigleLegam', sortable: true, searchable: true },
          { title: 'Description', field: 'bibDescription', sortable: true, searchable: true },
          { title: 'Scholars', field: 'authors', sortable: true, searchable: true },
          { title: 'Label', field: 'biblabels', sortable: true, searchable: true },
          { title: this.$t('Liability'), field: 'liability', sortable: true, searchable: true },
          { title: 'Action', field: 'id', tdComp: tdColAction, thClass: 'tdAction',
            transformData: function (row){
              return [
                { text: null, icon: 'list-ul', id: row.id, to: {
                  name: 'EditionDetails',
                  params: {
                    slug: row.sigleLegamNormalized || "-",
                    id: row.id
                  }}
                }
              ]
            }
          }
        ],
        columnsPI: [
          { title: 'Sigle', field: 'sigleLegam', sortable: true, searchable: true },
          { title: 'Description', field: 'bibDescription', sortable: true, searchable: true },
          { title: 'Scholars', field: 'authors', sortable: true, searchable: true },
          { title: 'Label', field: 'biblabels', sortable: true, searchable: false },
          { title: this.$t('Liability'), field: 'liability', sortable: true, searchable: true },
        ],
        url: '/editions/datatables',
        urlPublic: '/bib/editions.json',
        key: 1,
        searchableByColumn: true,
        selected: [],
        mainEntityId: null,
        config: config,
      }
    },
    computed: {
      ...mapState({
        userSettings: state => state.user.settings,
        userSettingsPI: state => state.user.settingsPI,
      }),
      tableAdditionalData(){
        return {
          'biblabel': this.userSettings.biblabels,
          'biblabelSuppressed': this.userSettings.biblabelsSuppressed,
        }
      }
    },
    components: {
      PageTitle,
      LegamDataTables,
      LegamDataTablesClientSide,
      EditionNewEdit,
      BibLabelSelectPI,
    },
    methods: {
      tableAdditionalDataFilter(data) {
        if (this.selectedBibLabels.length) {
          data = data.filter(row => this.selectedBibLabels.includes(row.biblabels))
        }
        return data
      },
      saveEdition(){
        this.$store.dispatch('createEdition', HelpStore.item).then((response) => {
          router.push(`/edition/${response.data.sigleLegamNormalized}/${response.data.id}`)
        })
      },
      selectChanged(items){
        this.selected = items
        if (items.length){
          this.mainEntityId = items[0].id
        }
      },
      openDetails(data) {
        router.push(`/edition/-/${data.id}`)
      },
      removeSelected(item) {
        this.selected = this.selected.filter(_item => item.id != _item.id)
      },
      merge() {
        this.$store.dispatch('mergeEditions', {
          mainEditionId: this.mainEntityId,
          editionIds: this.selected.filter(edition => edition.id != this.mainEntityId).map(edition => edition.id)
        }).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Studies merged`
          })
          this.selected = []
          this.mainEntityId = null
          this.key++
        })
      },
    },
    watch: {
      "userSettings"(){
        this.key++
      },
      userSettingsPI: {
        deep: true,
        handler() {
          if (this.$refs.PITable.table) {
            this.$refs.PITable.sortPageFilter()
          }
        }
      },
    }
  }
</script>
