<template>
  <div>
    <b-form-group
      id="sigle-legam-group"
      label="Sigle LEGaM:"
      label-for="sigle-legam"
    >
      <b-form-input
        id="sigle-legam"
        v-model="model.sigleLegam"
        placeholder="Sigle LEGaM"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="bib-description-group"
      label="BIB Description:"
      label-for="bib-description"
    >
      <b-form-textarea
        id="bib-description"
        placeholder="BIB Description"
        v-model="model.bibDescription"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group
      id="comment-general-group"
      label="Comment general:"
      label-for="comment-general"
    >
      <b-form-textarea
        id="comment-general"
        placeholder="Comment general"
        v-model="model.commentGeneral"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>


    <b-form-group
      id="former-edition-group"
      label="Former Edition:"
      label-for="former-edition"
    >
      <b-form-textarea
        id="former-edition"
        placeholder="Former Edition"
        v-model="model.formerEdition"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group
      id="former-description-group"
      label="Former Description:"
      label-for="former-description"
    >
      <b-form-textarea
        id="former-description"
        placeholder="Former Description"
        v-model="model.formerDescription"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>
  </div>
</template>

<style scoped>
</style>

<script>
  import HelpStore from '@/store/helpstore.js'

  export default {
    name: 'EditionNewEdit',
    props: ['edition'],
    data(){
      return {
        // TODO: fetch from API
        model: (this.edition ? this.edition : {
          sigleLegam: null,
          sigleAlt: null,
          bibDescription: null,
          commentGeneral: null
        })
      }
    },
    updated(){
      HelpStore.item = this.model
    },
    methods: {
      // selectCategory(category){
      //   this.model.categories.push(category)
      // }
    }
  }
</script>
